<template>
	<v-container>
		<v-card flat outlined :style="stickyTop">
			<v-card-title class="black white--text py-1">
				<span> {{ ledgerId }} - {{ ledgerName }} </span>
				<v-spacer />
				<v-btn icon @click.stop="$router.go(-1)"><v-icon color="white">mdi-close-circle-outline</v-icon></v-btn>
			</v-card-title>

			<v-data-table :headers="headers" :items="ledgerEntries" :loading="loading" disable-sort disable-pagination
				hide-default-footer no-data-text="" no-results-text="" dense fixed-header
				:height="sessionStore.contentHeight - 128 + 'px'">
				<template v-slot:[`item.datum`]="{ item }">
					{{ $d(new Date(item.datum), "short") }}
				</template>
				<template v-slot:[`item.credit`]="{ item }">
					<span v-if="item.credit">{{ $n(item.credit, "ccy") }}</span>
				</template>
				<template v-slot:[`item.debet`]="{ item }">
					<span v-if="item.debet">{{ $n(item.debet, "ccy") }}</span>
				</template>
			</v-data-table>
			<v-card-actions v-show="rowIdent">
				<v-spacer />
				<v-btn color="primary" text small :loading="loading" @click="getLedgerEntries()">
					meer tonen
				</v-btn>
				<v-spacer />
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import caas from "@/services/caas";
import { useSessionStore } from "@/store/session";
export default {
	name: "GeneralLedger",
	computed: {
		headers() {
			return [
				{ text: "Periode", value: "periode", width: "60px" },
				{ text: "Datum", value: "datum", width: "125px" },
				{ text: "Dagboek", value: "soort", width: "60px" },
				{ text: "Stuknr", value: "stuknr", width: "60px" },
				{ text: "Debet", value: "debet", align: "end", width: "150px" },
				{
					text: "Credit",
					value: "credit",
					align: "end",
					width: "150px",
				},
				{ text: "Naam", value: "naam" },
				{ text: "Ref", value: "ref" },
			];
		},
		stickyTop() {
			return {
				position: "sticky",
				top: this.$vuetify.application.top + "px",
				zIndex: 1,
			};
		},
	},
	data() {
		return {
			journal: "",
			ledgerId: 0,
			ledgerName: "",
			fiscalPeriod: 0,
			loading: false,
			ledgerEntries: [],
			rowIdent: "",
			rowsToBatch: 100,
			sessionStore: useSessionStore()
		};
	},
	methods: {
		async getLedgerEntries() {
			const sdoName = "boek/objects/dGrootb.r";
			let params = {
				action: "read",
				fiscalYear: Number(this.sessionStore.fiscalYear),
				fiscalPeriod: this.fiscalPeriod,
				rowIdent: this.rowIdent,
				nbrOfRows: this.rowsToBatch,
				setOpenQuery: `for each grootb no-lock where grootb.gnr = ${this.ledgerId}`,
				setQuerySort: "by periode by datum",
			};
			if (this.fiscalPeriod) {
				params.setOpenQuery += ` and grootb.periode = ${this.fiscalPeriod}`;
			}
			if (this.journal) {
				params.setOpenQuery += ` and grootb.soort begins "${this.journal}"`;
			}
			this.loading = true;
			const response = await caas.sdo(sdoName, params);
			this.ledgerEntries.push(...response.data.data.RowObject);

			let x = response.data.data.nbrOfRows;
			if (x === params.nbrOfRows) {
				this.rowIdent =
					response.data.data.RowObject[x - 1].RowIdent;
			} else {
				this.rowIdent = "";
			}
			this.loading = false;
		},
	},
	activated() {
		this.ledgerId = Number(this.$router.currentRoute.params.ledgerId);
		this.ledgerName = this.$router.currentRoute.params.ledgerName;
		this.journal = this.$router.currentRoute.params.journal;
		this.fiscalPeriod = this.$router.currentRoute.params.fiscalPeriod;
		this.getLedgerEntries();
	},
	deactivated() {
		this.ledgerEntries = [];
		this.rowIdent = "";
	},
};
</script>

<style>
</style>